<template>
  <div class="development">
    <head-nav></head-nav>
    <about-img></about-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="development-title">
        <img
          :src="$t('about.title-4')"
          alt=""
        >
      </h3>
      <div
        class="development-content"
      >
        <div class="development-info">
          {{$t('about.about-development.list-content-1')}}
        </div>
        <div class="development-box">
          <img
            :src=" $t('about.about-development.list-img-1')"
            alt=""
          >
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>

import returnImg from '@/assets/image/product/fanhui.png';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    AboutNav,
  },

  data() {
    return {
      breadcrumb: [
        {
          label: 'about.development',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.development {
  .wrap {
    padding: 88px 0 150px 0;

    .development-title {
      text-align: center;
    }

    .development-content {
      margin-top: 40px;
      font-size: 14px;
      line-height: 20px;

      .development-info {
        margin-bottom: 40px;
      }

      .development-box {
        text-align: center;
        width: 100%;
      }
    }
  }
}
</style>
